import { API as MOCK_AMPLIFY_API } from '../../__mocks__/aws-amplify';
import { API as AMPLIFY_API } from 'aws-amplify';
import {
  createParticipantMutation,
  confirmSignUpMutation,
  updateParticipantMutation,
  updateParticipantPreferencesMutation,
  withdrawParticipantMutation,
  updateParticipantEmailMutation,
  acceptLegalNoticesMutation,
  setAncestryFeedbackMutation,
} from '../graphql/mutations';
import {
  getParticipantQuery,
  getParticipantPreferencesQuery,
  getConsentPdfLinkQuery,
  getEhrStatusQuery,
  getImmunisationsQuery,
  getConditionsQuery,
  getAncestryQuery,
  getMedicationsQuery,
  getAllergiesQuery,
} from '../graphql/queries';
import {
  CreateParticipantInput,
  UpdateParticipantInput,
  UpdateParticipantPreferencesInput,
  CreateParticipantResponse,
  GetParticipantResponse,
  ConfirmSignupResponse,
  GetParticipantPreferencesResponse,
  UpdateParticipantPreferencesResponse,
  WithdrawParticipantInput,
  WithdrawParticipantResponse,
  GetConsentPdfLinkResponse,
  UpdateParticipantEmailResponse,
  AcceptLegalNoticesResponse,
  AcceptLegalNoticesInput,
  GetEhrStatusResponse,
  GetImmunisationsResponse,
  GetConditionsResponse,
  GetAncestryResponse,
  GetMedicationsResponse,
  GetAllergiesResponse,
  SetAncestryFeedbackInput,
} from './portal.types';
import { CONSENT_DATE, CONSENT_VERSION, PIS_DATE, PIS_VERSION } from '../constants/content.constants';

const API = process.env.REACT_APP_USE_MOCKS === 'true' ? MOCK_AMPLIFY_API : AMPLIFY_API;

const portalApi = {
  confirmSignup: async (loginMethod: string, loginParameter: string, odsCode?: string): Promise<any> => {
    try {
      return (await API.graphql({
        query: confirmSignUpMutation,
        variables: {
          loginMethod,
          loginParameter,
          odsCode,
        },
        authMode: 'AWS_IAM',
      })) as ConfirmSignupResponse;
    } catch (error: any) {
      return error as ConfirmSignupResponse;
    }
  },
  createParticipant: async (values: CreateParticipantInput): Promise<any> => {
    try {
      return (await API.graphql({
        query: createParticipantMutation,
        variables: {
          input: {
            firstName: values.firstName,
            surname: values.surname,
            dob: values.dob,
            sexAtBirth: values.sexAtBirth,
            genderIdentity: values.genderIdentity,
            genderIdentityOther: values.genderIdentityOther,
            mobile: values.mobile,
            landline: values.landline,
            postcode: values.postcode,
            address1: values.address1,
            address2: values.address2,
            town: values.town,
            NHSNumber: values.NHSNumber,
            ehrOptin: values.ehrOptin === 'true' ? true : false,
            ancestryOptin: values.ancestryOptin === 'true' ? true : false,
            communicationOptin: true,
            PISConfirm: true,
            VideoConfirm: true,
            consent1: true,
            consent2: true,
            consent4: true,
            consent3: true,
            consent5: true,
            consent6: true,
            consent7: true,
            consent8: true,
            consent9: true,
            PISVersion: PIS_VERSION,
            PISDate: PIS_DATE,
            consentFormVersion: CONSENT_VERSION,
            consentFormDate: CONSENT_DATE,
          },
        },
      })) as CreateParticipantResponse;
    } catch (error) {
      return error as CreateParticipantResponse;
    }
  },
  updateParticipant: async (values: UpdateParticipantInput): Promise<any> => {
    try {
      return (await API.graphql({
        query: updateParticipantMutation,
        variables: {
          input: {
            genderIdentity: values.genderIdentity,
            genderIdentityOther: values.genderIdentityOther,
            mobile: values.mobile,
            landline: values.landline,
            postcode: values.postcode,
            address1: values.address1,
            address2: values.address2,
            town: values.town,
          },
        },
      })) as CreateParticipantResponse;
    } catch (error) {
      return error as CreateParticipantResponse;
    }
  },
  getParticipant: async (): Promise<any> => {
    try {
      return (await API.graphql({
        query: getParticipantQuery,
      })) as GetParticipantResponse;
    } catch (error) {
      return error as GetParticipantResponse;
    }
  },
  getConsentPdfLink: async (): Promise<any> => {
    try {
      return (await API.graphql({
        query: getConsentPdfLinkQuery,
      })) as GetConsentPdfLinkResponse;
    } catch (error) {
      return error as GetConsentPdfLinkResponse;
    }
  },
  getEhrStatus: async (): Promise<any> => {
    try {
      return (await API.graphql({
        query: getEhrStatusQuery,
      })) as GetEhrStatusResponse;
    } catch (error) {
      return error as GetEhrStatusResponse;
    }
  },
  getImmunisations: async (): Promise<any> => {
    try {
      return (await API.graphql({
        query: getImmunisationsQuery,
      })) as GetImmunisationsResponse;
    } catch (error) {
      return error as GetImmunisationsResponse;
    }
  },
  getConditions: async (): Promise<any> => {
    try {
      return (await API.graphql({
        query: getConditionsQuery,
      })) as GetConditionsResponse;
    } catch (error) {
      return error as GetConditionsResponse;
    }
  },
  getAncestry: async (): Promise<any> => {
    try {
      return (await API.graphql({
        query: getAncestryQuery,
      })) as GetAncestryResponse;
    } catch (error) {
      return error as GetAncestryResponse;
    }
  },
  getMedications: async (): Promise<any> => {
    try {
      return (await API.graphql({
        query: getMedicationsQuery,
      })) as GetMedicationsResponse;
    } catch (error) {
      return error as GetMedicationsResponse;
    }
  },
  getAllergies: async (): Promise<any> => {
    try {
      return (await API.graphql({
        query: getAllergiesQuery,
      })) as GetAllergiesResponse;
    } catch (error) {
      return error as GetAllergiesResponse;
    }
  },
  getParticipantPreferences: async (): Promise<any> => {
    try {
      return (await API.graphql({
        query: getParticipantPreferencesQuery,
      })) as GetParticipantPreferencesResponse;
    } catch (error) {
      return error as GetParticipantPreferencesResponse;
    }
  },
  updateParticipantPreferences: async (values: UpdateParticipantPreferencesInput): Promise<any> => {
    try {
      return (await API.graphql({
        query: updateParticipantPreferencesMutation,
        variables: {
          input: {
            ehrOptin: values.ehrOptin,
            ancestryOptin: values.ancestryOptin,
            communicationOptin: values.communicationOptin,
          },
        },
      })) as UpdateParticipantPreferencesResponse;
    } catch (error) {
      return error as UpdateParticipantPreferencesResponse;
    }
  },
  setAncestryFeedback: async (values: SetAncestryFeedbackInput): Promise<any> => {
    try {
      return (await API.graphql({
        query: setAncestryFeedbackMutation,
        variables: {
          input: {
            expectedGroups: values.expectedGroups,
            matchesExpectations: values.matchesExpectations,
          },
        },
      })) as UpdateParticipantPreferencesResponse;
    } catch (error) {
      return error as UpdateParticipantPreferencesResponse;
    }
  },
  withdrawParticipant: async (values: WithdrawParticipantInput): Promise<any> => {
    try {
      return (await API.graphql({
        query: withdrawParticipantMutation,
        variables: {
          withdrawalLevel: values.withdrawalLevel,
          withdrawalReason: values.withdrawalReason,
        },
      })) as WithdrawParticipantResponse;
    } catch (error) {
      return error as WithdrawParticipantResponse;
    }
  },
  updateParticipantEmail: async (code: string): Promise<UpdateParticipantEmailResponse> => {
    try {
      return (await API.graphql({
        query: updateParticipantEmailMutation,
        variables: {
          code,
        },
      })) as UpdateParticipantEmailResponse;
    } catch (error) {
      return error as UpdateParticipantEmailResponse;
    }
  },
  acceptLegalNotices: async (values: AcceptLegalNoticesInput): Promise<AcceptLegalNoticesResponse> => {
    try {
      return (await API.graphql({
        query: acceptLegalNoticesMutation,
        variables: {
          input: {
            ...values,
          },
        },
      })) as AcceptLegalNoticesResponse;
    } catch (error) {
      return error as AcceptLegalNoticesResponse;
    }
  },
};

export default portalApi;
