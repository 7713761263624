/** @jsxImportSource @emotion/react */
import { useRef, useEffect, useState, useCallback } from 'react';
import { select } from 'd3';
import { useStyles } from '../../../component.styles';
import Box from '@mui/material/Box';
import { drawGroups, drawWorldMap, togglePin } from '../../../utils/map.utils';
import { useTranslation } from 'react-i18next';
import AncestryTableInfo from '../../AncestryTableInfo';

export interface AncestryMapProps {
  ancestryData: AncestryLocation[];
  ancestryTableData: AncestryTableData[];
  title: string;
}

interface AncestryLocation {
  lat: number;
  lng: number;
  group: string;
  regionIndex: number;
  groupIndex: number;
  groupLength: number;
  geneticRegion: string;
}

interface AncestryTableData {
  groups: AncestryTableDataItem[];
  geneticRegionLabel: string;
  geneticRegion: string;
}

interface AncestryTableDataItem {
  group: string;
  groupLabel: string;
  geneticRegion: string;
  groupDescription: string;
}

function AncestryMap({ ancestryData, ancestryTableData }: AncestryMapProps) {
  const { t } = useTranslation('portal');
  const ref = useRef(null as unknown as SVGSVGElement);
  const classes = useStyles();
  const [expandedPanel, setExpandedPanel] = useState<number | null>(null); // state to track the expanded accordion
  const [selectedRow, setSelectedRow] = useState<string | null>(null); // state to track the selected row

  const handleRegionClick = useCallback(
    (geneticRegion: string, group: string) => {
      const index = ancestryTableData.findIndex((region) => region.geneticRegion === geneticRegion);
      setExpandedPanel(index); // toggle accordion panel
      setSelectedRow(group);
    },
    [ancestryTableData],
  );

  const handleGroupRowClick = (geneticRegion: string, group: string) => {
    setSelectedRow(group);

    // Find the corresponding location data for the clicked group
    const selectedLocation = ancestryData.find(
      (location) => location.geneticRegion === geneticRegion && location.group === group,
    );

    if (selectedLocation && ref.current) {
      const svg = select(ref.current); // The SVG element for map rendering
      togglePin(selectedLocation, null, svg); // Call togglePin to add/remove pin on the map
    }
  };

  const handleAccordionChange = (panelIndex: number) => {
    setExpandedPanel(panelIndex === expandedPanel ? null : panelIndex); // toggle accordion panel
  };

  useEffect(() => {
    if (ref.current) {
      const svg = select<SVGSVGElement, null>(ref.current);
      const map = svg.append('g');

      map.call(drawWorldMap);
      drawGroups(ancestryData, map, handleRegionClick);
    }
  }, [ancestryData, handleRegionClick]);

  return (
    <>
      <p>{t('ancestry.tabs.allGroupsMap.descriptionParagraph1')}</p>
      <p>{t('ancestry.tabs.allGroupsMap.descriptionParagraph2')}</p>
      <p>{t('ancestry.tabs.allGroupsMap.descriptionParagraph3')}</p>

      <Box css={classes.ancestry} data-testid="ancestry-map">
        <svg width="100%" height="100%" viewBox="50 -50 900 500" preserveAspectRatio="xMaxYMax meet" ref={ref} />

        <AncestryTableInfo
          expandedPanel={expandedPanel}
          handleAccordionChange={handleAccordionChange}
          ancestryTableData={ancestryTableData}
          selectedRow={selectedRow}
          handleGroupRowClick={handleGroupRowClick}
          testId="all_groups"
        />
      </Box>
    </>
  );
}

export default AncestryMap;
