/** @jsxImportSource @emotion/react */
import { Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { useStyles } from '../../component.styles';

function UnderstandingAncestryEstimate() {
  const classes = useStyles();
  const { t } = useTranslation('portal');

  return (
    <Paper css={classes.ancestryInfoBox}>
      <Typography variant="h5" component="h1" paragraph>
        {t('ancestry.understandingSection.title')}
      </Typography>
      <p>{t('ancestry.understandingSection.descriptionParagraph1')}</p>
      <p>{t('ancestry.understandingSection.descriptionParagraph2')}</p>
      <p>{t('ancestry.understandingSection.descriptionParagraph3')}</p>
      <ul>
        <li>
          <strong>{t('ancestry.understandingSection.bulletPointText1Bold')}</strong>
          {t('ancestry.understandingSection.bulletPointText1')}
        </li>
        <li>
          <strong>{t('ancestry.understandingSection.bulletPointText2Bold')}</strong>
          {t('ancestry.understandingSection.bulletPointText2')}
        </li>
        <li>
          <strong>{t('ancestry.understandingSection.bulletPointText3Bold')}</strong>
          {t('ancestry.understandingSection.bulletPointText3')}
        </li>
      </ul>
    </Paper>
  );
}

export default UnderstandingAncestryEstimate;
