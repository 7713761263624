import { feature } from 'topojson-client';
import { Topology } from 'topojson-specification';
import { geoNaturalEarth1, geoPath } from 'd3';
import * as d3 from 'd3';
import { getColors } from '../component.styles';
import data_ from './world.json';

interface AncestryLocation {
  lat: number;
  lng: number;
  wt?: number;
  label?: string;
  group: string;
  regionIndex: number;
  groupIndex: number;
  groupLength: number;
  geneticRegion: string;
}

const data: Topology = data_ as unknown as Topology;

const projection = geoNaturalEarth1();
const pathGenerator = geoPath().projection(projection);
const countries: any = feature(data, data.objects.countries);
const tooltip = d3.select('body').append('div').style('position', 'absolute').style('background', '#ffffff');

const showTooltip = (d: any, text: string, event: any) => {
  tooltip
    .style('opacity', 1)
    .html(`<strong>${text}</strong>`)
    .style('left', event.pageX + 10 + 'px')
    .style('top', event.pageY - 30 + 'px')
    .style('font-size', '12px')
    .style('padding', '5px')
    .style('border', '1px solid grey')
    .style('border-radius', '10px');
};

/* draws world map */
export const drawWorldMap = (selection: any) => {
  selection
    .selectAll('path')
    .data(countries.features)
    .enter()
    .append('path')
    .attr('d', (d: any) => pathGenerator(d));
};

export const drawGroups = (locations: AncestryLocation[], selection: any, setSelectedRegion: any) => {
  /* creates mask around boundaries of world map */
  selection.append('defs').append('clipPath').attr('id', 'mapMask').call(drawWorldMap);
  /* creates blur filter  */
  selection.append('defs').append('filter').attr('id', 'blur').append('feGaussianBlur').attr('stdDeviation', '8');

  /* plots group locations on map */
  selection
    .append('g')
    .selectAll('circle')
    .data(locations)
    .enter()
    .append('circle')
    .attr('data-testid', (d: AncestryLocation) => d.group)
    .style('stroke', (d: AncestryLocation) => getColors(d.groupIndex, d.groupLength, null, d.geneticRegion))
    .style('fill', (d: AncestryLocation) => getColors(d.groupIndex, d.groupLength, null, d.geneticRegion))
    .attr('cx', (d: any) => projection([d.lng, d.lat])![0])
    .attr('cy', (d: any) => projection([d.lng, d.lat])![1])
    .attr('r', 13)
    .style('filter', 'url(#blur)')
    .attr('clip-path', 'url(#mapMask)')
    .on('mouseover', (event: any, d: any) => {
      showTooltip(d, d.label, event);
    })
    .on('mouseleave', () => {
      tooltip.style('opacity', 0);
    })
    .on('click', (event: any, d: any) => {
      // Toggle pin on map blob click
      togglePin(d, event.target, selection);
      setSelectedRegion(d.geneticRegion, d.group);
    });
};
// Keep track of the currently selected group and its pin
let currentSelectedGroup: AncestryLocation | null = null;
let currentPin: any = null;

export const togglePin = (d: AncestryLocation, target: any, selection: any) => {
  // If the clicked item already has a pin, remove it
  if (currentSelectedGroup === d) {
    // Remove the pin
    if (currentPin) {
      currentPin.remove(); // Remove the pin from the map
    }
    currentSelectedGroup = null; // Reset the selected group
  } else {
    // If it's a new selection, remove the previous pin
    if (currentPin) {
      currentPin.remove(); // Remove the previous pin
    }

    // Create a pin at the clicked location (you can customize the pin style)
    // Use the existing selection (which represents the map SVG container)
    const pin = selection
      .append('circle') // Append to the selection (map container)
      .attr('data-testid', `region_highlight_${d.geneticRegion}_${d.group}`)
      .attr('cx', projection([d.lng, d.lat])![0])
      .attr('cy', projection([d.lng, d.lat])![1])
      .attr('r', 10) // Adjust the size of the pin
      .style('fill', 'red'); // Pin color (you can customize it)

    const pulse = () => {
      pin
        .transition()
        .duration(1000) // Time taken to grow and shrink the circle
        .attr('r', 15) // Larger radius during the pulse
        .style('opacity', 0.6) // Optional: reduce opacity during the pulse for effect
        .ease(d3.easeQuadInOut) // Smooth easing function for the transition
        .transition()
        .duration(1000) // Same duration for shrinking
        .attr('r', 10) // Smaller radius when the pulse is completed
        .style('opacity', 0.8) // Reset opacity
        .ease(d3.easeQuadInOut)
        .on('end', pulse); // Repeat the pulse effect
    };

    // Start pulsing
    pulse();

    currentPin = pin; // Save the reference to the pin
    currentSelectedGroup = d; // Set the selected group
  }
};

export const drawPopulations = (locations: AncestryLocation[], selection: any) => {
  /* plots group locations on map */
  selection
    .append('g')
    .selectAll('circle')
    .data(locations)
    .enter()
    .append('circle')
    .attr('data-testid', (d: AncestryLocation) => d.group)
    .style('stroke', (d: AncestryLocation) => getColors(d.groupIndex, d.groupLength, null, d.geneticRegion))
    .style('fill', (d: AncestryLocation) => getColors(d.groupIndex, d.groupLength, null, d.geneticRegion))
    .attr('cx', (d: any) => projection([d.lng, d.lat])![0])
    .attr('cy', (d: any) => projection([d.lng, d.lat])![1])
    .attr('r', (d: any) => d.wt * 10 + 3)
    .on('mouseover', (event: any, d: any) => {
      showTooltip(d, d.label, event);
    })
    .on('mouseleave', () => {
      tooltip.style('opacity', 0);
    });
};
