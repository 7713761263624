import { createAsyncThunk } from '@reduxjs/toolkit';
import portalApi from '../../core/api/portal.api';
import {
  GetAncestryResponse,
  GraphQlError,
  SetAncestryFeedbackInput,
  SetAncestryFeedbackResponse,
} from '../../core/api/portal.types';

export const getAncestryThunk = createAsyncThunk<
  // Return type of the payload creator
  GetAncestryResponse,
  // First argument to the payload creator
  undefined,
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('ehr/getAncestryThunk', async (values, thunkApi) => {
  const response: GetAncestryResponse = await portalApi.getAncestry();

  if (response.errors) {
    return thunkApi.rejectWithValue(response.errors);
  }

  const _response: GetAncestryResponse = response.data?.getAncestry as GetAncestryResponse;

  return _response;
});

export const setAncestryFeedbackThunk = createAsyncThunk<
  // Return type of the payload creator
  SetAncestryFeedbackResponse,
  // First argument to the payload creator
  SetAncestryFeedbackInput,
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('ehr/setAncestryFeedbackThunk', async (values, thunkApi) => {
  const response: SetAncestryFeedbackResponse = await portalApi.setAncestryFeedback(values);

  if (response.errors) {
    return thunkApi.rejectWithValue(response.errors);
  }
  return response.data?.setAncestryFeedback as SetAncestryFeedbackResponse;
});
