import { RootState } from '../index';
import { StoreStatus } from '../../core/types/common.types';
import { Ancestry } from '../../core/api/portal.types';

export const hasErrorSelector = (state: RootState): boolean =>
  state.ancestry.errors && state.ancestry.errors ? true : false;

export const isBusySelector = (state: RootState): boolean => state.ancestry.status === StoreStatus.BUSY;

export const responseSelector = (state: RootState): Ancestry | null => state.ancestry.response || null;

export const hasSuccessSelector = (state: RootState): boolean => state.ancestry.response?.status === 'complete';

export const hasUpdatedSelector = (state: RootState): boolean => state.ancestry.hasUpdated === true;

export const hasPendingSelector = (state: RootState): boolean => state.ancestry.response?.status === 'pending';

export const isAncestryCautionSelector = (state: RootState): boolean =>
  state.ancestry.response?.displayStatus === 'CAUTION';

export const isAncestryDisplaySelector = (state: RootState): boolean =>
  state.ancestry.response?.displayStatus === 'DISPLAY';

export const isAncestryWithholdSelector = (state: RootState): boolean =>
  state.ancestry.response?.displayStatus === 'WITHHOLD';

export const isAncestryFeedbackSubmitted = (state: RootState): boolean =>
  state.ancestry.response?.feedbackSubmitted == true;
