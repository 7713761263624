import { createSlice } from '@reduxjs/toolkit';
import { AncestryState } from './types';
import { AncestryDisplayStatus, StoreStatus } from '../../core/types/common.types';
import { getAncestryThunk, setAncestryFeedbackThunk } from './thunks';

// Initial State
export const initialState: AncestryState = {
  errors: null,
  status: StoreStatus.IDLE,
  displayStatus: AncestryDisplayStatus.DISPLAY,
  feedbackSubmitted: false,
  response: null,
  hasChecked: false,
  hasUpdated: false,
};

// Slice
export const ancestrySlice = createSlice({
  name: 'ancestry',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getAncestryThunk.pending.type, (state) => {
      state.status = StoreStatus.BUSY;
    });

    builder.addCase(getAncestryThunk.fulfilled.type, (state, action: any) => {
      state.status = StoreStatus.IDLE;
      state.response = action.payload;
      state.errors = null;
    });

    builder.addCase(getAncestryThunk.rejected.type, (state, { payload }: any) => {
      state.status = StoreStatus.ERROR;
      state.errors = payload;
      state.response = null;
    });

    builder.addCase(setAncestryFeedbackThunk.pending.type, (state) => {
      state.status = StoreStatus.BUSY;
      state.hasUpdated = false;
    });

    builder.addCase(setAncestryFeedbackThunk.fulfilled.type, (state, action: any) => {
      state.status = StoreStatus.IDLE;
      state.response = action.payload;
      state.hasChecked = true;
      state.hasUpdated = true;
      state.errors = null;
    });

    builder.addCase(setAncestryFeedbackThunk.rejected.type, (state, { payload }: any) => {
      state.status = StoreStatus.ERROR;
      state.errors = payload;
      state.response = null;
    });
  },
});

// Action creators
export const { reset } = ancestrySlice.actions;
export * from './thunks';
export * from './selectors';
export * from './types';
export default ancestrySlice.reducer;
