/** @jsxImportSource @emotion/react */
import { Ancestry, AncestryRegion } from '../../../../../../core/api/portal.types';
import { Tabs } from '@omnigenbiodata/ui';
import MatchingGroupsMap from './MatchingGroupsMap';
import AllGroupsMap from './AllGroupsMap';
import AllPopulationsMap from './AllPopulationsMap';

export interface AncestryMapProps {
  ancestryData: Ancestry;
}

interface AncestryLocation {
  lat: number;
  lng: number;
  wt?: number;
  label?: string;
  group: string;
  regionIndex: number;
  groupIndex: number;
  groupLength: number;
  geneticRegion: string;
}

interface AncestryTableData {
  groups: AncestryTableDataItem[];
  geneticRegionLabel: string;
  geneticRegion: string;
}

interface AncestryTableDataItem {
  group: string;
  groupLabel: string;
  geneticRegion: string;
  groupDescription: string;
}

function AncestryMap({ ancestryData }: AncestryMapProps) {
  console.log(ancestryData);

  const ancestryTableData: AncestryTableData[] = ancestryData.regions.map((region) => ({
    geneticRegionLabel: region.geneticRegionLabel,
    geneticRegion: region.geneticRegion.toLowerCase(),
    groups: region.groups.map((group) => ({
      group: group.group.toLowerCase(),
      groupLabel: group.label,
      groupDescription: group.description,
      geneticRegion: region.geneticRegion.toLowerCase(),
    })),
  }));

  const ancestryMatchedTableData: AncestryTableData[] = ancestryData.regions
    .filter(({ percentage }) => percentage > 0)
    .map((region) => ({
      geneticRegionLabel: region.geneticRegionLabel,
      geneticRegion: region.geneticRegion.toLowerCase(),
      groups: region.groups.map((group) => ({
        group: group.group.toLowerCase(),
        groupLabel: group.label,
        groupDescription: group.description,
        geneticRegion: region.geneticRegion.toLowerCase(),
      })),
    }));

  const allLocations = ancestryData.regions.reduce<AncestryLocation[]>((list, item: AncestryRegion, index: number) => {
    for (let i = 0; i < item.groups.length; i++) {
      for (const location of item.groups[i].locations) {
        list.push({
          ...location,
          label: item.groups[i].label,
          group: item.groups[i].group.toLowerCase(),
          regionIndex: index,
          groupIndex: i,
          groupLength: item.groups.length,
          geneticRegion: item.geneticRegion.toLowerCase(),
        });
      }
    }

    return list;
  }, []);

  const matchedLocations = ancestryData.regions
    .filter(({ percentage }) => percentage > 0)
    .reduce<AncestryLocation[]>((list, item: AncestryRegion, index: number) => {
      const groups = item.groups.filter(({ percentage }) => percentage > 0);
      for (let i = 0; i < groups.length; i++) {
        for (const location of groups[i].locations) {
          list.push({
            ...location,
            label: groups[i].label,
            group: groups[i].group.toLowerCase(),
            regionIndex: index,
            groupIndex: i,
            groupLength: groups.length,
            geneticRegion: item.geneticRegion.toLowerCase(),
          });
        }
      }
      return list;
    }, []);

  const populationsData = ancestryData.regions.reduce<AncestryLocation[]>(
    (list, item: AncestryRegion, index: number) => {
      for (let i = 0; i < item.groups.length; i++) {
        for (const population of item.groups[i].populations) {
          list.push({
            ...population.location,
            group: item.groups[i].label,
            label: population.label,
            regionIndex: index,
            groupIndex: i,
            groupLength: item.groups.length,
            geneticRegion: item.geneticRegion,
          });
        }
      }
      return list;
    },
    [],
  );

  return (
    <Tabs>
      <MatchingGroupsMap
        title="My Ancestry"
        ancestryData={matchedLocations}
        ancestryTableData={ancestryMatchedTableData}
      />
      <AllGroupsMap title="All Groups" ancestryData={allLocations} ancestryTableData={ancestryTableData} />
      <AllPopulationsMap title="All Populations" ancestryData={populationsData} />
    </Tabs>
  );
}

export default AncestryMap;
