import PortalLayout from '../../../../layouts/PortalLayout';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Message, SectionBox, Tabs, FormStepper, theme } from '@omnigenbiodata/ui';
import ReactMarkdown from 'react-markdown';
import { MdOutlineNoFood, MdOutlineToday, MdOutlinePerson, MdOutlinePeople } from 'react-icons/md';
import { RiFileDamageLine } from 'react-icons/ri';
import { STUDY_EMAIL } from '../../../../core/constants/content.constants';
import InstructionRow from './components/InstructionRow';
import TipRow from './components/TipRow';
import { useAppSelector } from '../../../../store';
import { responseSelector } from '../../../../store/participant';
import { OrderStatus } from '../../../../core/api/portal.types';
import useMediaQuery from '@mui/material/useMediaQuery';

function SamplesScene() {
  const { t } = useTranslation('sampleCollection');
  const participant = useAppSelector(responseSelector);

  const getStatusStep = (orderStatus: string) => {
    switch (orderStatus) {
      case OrderStatus.PENDING:
        return 0;
      case OrderStatus.DISPATCHED:
        return 1;
      case OrderStatus.RECEIVED:
        return 2;
      case OrderStatus.STORED:
        return 2;
      case OrderStatus.PROCESSED:
        return 4;
      default:
        return 0;
    }
  };

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <PortalLayout>
      <Typography textAlign="center" variant="h5" component="h1" paragraph>
        {t('samples.title', { ns: 'portal' })}
      </Typography>
      <SectionBox>
        {participant.orderStatus === OrderStatus.PENDING && (
          <Message title={t('samples.messages.pending.title', { ns: 'portal' }) as string} severity="info">
            {t('samples.messages.pending.text', { ns: 'portal' })}
          </Message>
        )}
        {participant.orderStatus === OrderStatus.DISPATCHED && (
          <Message title={t('samples.messages.dispatched.title', { ns: 'portal' }) as string} severity="info">
            {t('samples.messages.dispatched.text', { ns: 'portal' })}
          </Message>
        )}
        {(participant.orderStatus === OrderStatus.RECEIVED || participant.orderStatus === OrderStatus.STORED) && (
          <Message title={t('samples.messages.received.title', { ns: 'portal' }) as string} severity="info">
            {t('samples.messages.received.text', { ns: 'portal' })}
          </Message>
        )}
        <Box mb={2} sx={{ overflowY: { xs: 'auto' } }}>
          <FormStepper
            activeStep={getStatusStep(participant?.orderStatus as string)}
            steps={['Preparing', 'Dispatched', 'Received', 'Processed']}
          />
        </Box>
        <Typography textAlign="left" variant="h6" component="h2" paragraph>
          {t('title1')}
        </Typography>
        <Typography textAlign="left" variant="body1" fontWeight="bold" paragraph>
          {t('text1')}
        </Typography>
        <Typography textAlign="left" variant="body1" component="span" paragraph>
          <ReactMarkdown>{t('text2')}</ReactMarkdown>
        </Typography>
        <Box py={3} px={isMobile ? 0 : 3} textAlign="center">
          <iframe
            width={isMobile ? '100%' : '560'}
            height={isMobile ? 'auto' : '315'}
            src="https://www.youtube-nocookie.com/embed/CE5roPspO3o"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Box>
        <Tabs>
          <div title="Tips">
            <Typography textAlign="left" variant="h6" component="h2" paragraph>
              {t('title2')}
            </Typography>
            <SectionBox color="info">
              <TipRow icon={MdOutlineNoFood} markdown={t('text3', { studyEmail: STUDY_EMAIL })} />
              <TipRow icon={MdOutlineToday} markdown={t('text4', { studyEmail: STUDY_EMAIL })} />
              <TipRow icon={MdOutlinePerson} markdown={t('text5', { studyEmail: STUDY_EMAIL })} />
              <TipRow icon={MdOutlinePeople} markdown={t('text6', { studyEmail: STUDY_EMAIL })} />
              <TipRow icon={RiFileDamageLine} markdown={t('text7', { studyEmail: STUDY_EMAIL })} />
            </SectionBox>
          </div>
          <div title="Instructions">
            <Typography textAlign="left" variant="h6" component="h2" paragraph>
              {t('title3')}
            </Typography>
            {Object.values(t('instructions', { returnObjects: true })).map((instruction, iIndex) => (
              <InstructionRow key={`instruction-${iIndex}`} markdown={instruction} number={iIndex + 1} />
            ))}
          </div>
        </Tabs>
      </SectionBox>
    </PortalLayout>
  );
}

export default SamplesScene;
