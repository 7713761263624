/** @jsxImportSource @emotion/react */
import { useRef, useEffect } from 'react';
import { select } from 'd3';
import { useStyles } from '../../../component.styles';
import Box from '@mui/material/Box';
import { drawPopulations, drawWorldMap } from '../../../utils/map.utils';
import { useTranslation } from 'react-i18next';

export interface AncestryMapProps {
  ancestryData: AncestryLocation[];
  title: string;
}

interface AncestryLocation {
  lat: number;
  lng: number;
  label?: string;
  group: string;
  regionIndex: number;
  groupIndex: number;
  groupLength: number;
  geneticRegion: string;
}

function AncestryMap({ ancestryData }: AncestryMapProps) {
  const { t } = useTranslation('portal');
  const ref = useRef(null as unknown as SVGSVGElement);
  const classes = useStyles();

  useEffect(() => {
    if (ref.current) {
      const svg = select<SVGSVGElement, null>(ref.current);
      const map = svg.append('g');

      map.call(drawWorldMap);
      drawPopulations(ancestryData, map);
    }
  }, [ancestryData]);

  return (
    <>
      <p>{t('ancestry.tabs.allPopulationsMap.descriptionParagraph1')}</p>
      <p>{t('ancestry.tabs.allPopulationsMap.descriptionParagraph2')}</p>
      <p>{t('ancestry.tabs.allPopulationsMap.descriptionParagraph3')}</p>
      <Box css={classes.ancestry} data-testid="ancestry-map">
        <svg width="100%" height="100%" viewBox="50 -50 900 500" preserveAspectRatio="xMaxYMax meet" ref={ref} />
      </Box>
    </>
  );
}

export default AncestryMap;
