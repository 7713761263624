/** @jsxImportSource @emotion/react */
import { Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { useStyles } from '../../../component.styles';

function AncestryFeedbackThankYou() {
  const classes = useStyles();
  const { t } = useTranslation('portal');

  return (
    <Paper css={classes.ancestryFormSuccessBox}>
      <Typography variant="h5" component="h1" paragraph>
        {t('ancestry.howYouCanHelpSection.matchesExpectations.submitted.title')}
      </Typography>
      <p>{t('ancestry.howYouCanHelpSection.matchesExpectations.submitted.descriptionParagraph1')}</p>
      <p>{t('ancestry.howYouCanHelpSection.matchesExpectations.submitted.descriptionParagraph2')}</p>
    </Paper>
  );
}

export default AncestryFeedbackThankYou;
