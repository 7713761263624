/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import PortalLayout from '../../../../layouts/PortalLayout';
import { Loader, Message, SectionBox } from '@omnigenbiodata/ui';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  responseSelector,
  hasErrorSelector,
  isBusySelector,
  getAncestryThunk,
  hasSuccessSelector,
  hasPendingSelector,
  isAncestryCautionSelector,
  isAncestryFeedbackSubmitted,
  hasUpdatedSelector,
} from '../../../../store/ancestry';
import AncestryMaps from './components/AncestryMaps';
import Grid from '@mui/material/Grid';
import AncestryList from './components/AncestryList';
import AncestryGraph from './components/AncestryGraph';
import { Paper } from '@mui/material';
import { useStyles } from './component.styles';
import AncestryFeedback from './components/AncestryFeedback';
import UnderstandingAncestryEstimate from './components/UnderstandingAncestryEstimate';
import AncestryFeedbackThankYou from './components/AncestryFeedback/AncestryFeedbackThankYou';

function AncestryScene() {
  const { t } = useTranslation('portal');
  const dispatch = useAppDispatch();
  const isBusy = useAppSelector(isBusySelector);
  const hasError = useAppSelector(hasErrorSelector);
  const response = useAppSelector(responseSelector);
  const hasSuccess = useAppSelector(hasSuccessSelector);
  const hasPending = useAppSelector(hasPendingSelector);
  const hasUpdated = useAppSelector(hasUpdatedSelector);
  const isAncestryCaution = useAppSelector(isAncestryCautionSelector);
  const isFeedbackSubmitted = useAppSelector(isAncestryFeedbackSubmitted);
  const [displayThankYouMsg, setDisplayThankYouMsg] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    if (!response) {
      dispatch(getAncestryThunk());
    }
  }, [dispatch, response]);

  useEffect(() => {
    if (hasUpdated) {
      dispatch(getAncestryThunk());
    }
  }, [dispatch, hasUpdated, displayThankYouMsg]);

  return (
    <PortalLayout>
      <SectionBox>
        <Typography variant="h5" component="h1" paragraph>
          {t('ancestry.title')}
        </Typography>

        {hasError ||
          (hasPending && (
            <Message severity="error" title={`${t('alerts.AncestryFailed.title')}`}>
              {t('alerts.AncestryFailed.text')}
            </Message>
          ))}
        {hasSuccess}
        {response && hasSuccess && (
          <>
            <div>
              {isAncestryCaution && (
                <Paper data-testid="caution-display-text" css={classes.ancestryDisplayStatusBox}>
                  <p>{t('ancestry.cautionText')}</p>
                </Paper>
              )}
              <p>{t('ancestry.descriptionParagraph1')}</p>
            </div>
            <Grid container mt={3}>
              <Grid item xs={12} sm={6} pl={{ sm: 1 }} pr={{ md: 5 }}>
                <AncestryList ancestryData={response} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <AncestryGraph ancestryData={response} />
              </Grid>
            </Grid>
            <Grid container mt={3}>
              <Grid item xs={12}>
                <AncestryMaps ancestryData={response} />
              </Grid>
            </Grid>
            <UnderstandingAncestryEstimate />
            {!isFeedbackSubmitted && (
              <AncestryFeedback ancestryData={response} setDisplayThankYouMsg={setDisplayThankYouMsg} />
            )}
            {displayThankYouMsg && isFeedbackSubmitted && <AncestryFeedbackThankYou />}
          </>
        )}
      </SectionBox>
      <Loader isVisible={isBusy} label={t('loaders.waiting') as string} />
    </PortalLayout>
  );
}

export default AncestryScene;
