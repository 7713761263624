import { css } from '@emotion/react';
import { theme } from '@omnigenbiodata/ui';
import { scaleLinear } from 'd3';
import { ANCESTRY_REGIONS_LIST } from '../../../../core/constants/content.constants';

export const regionColors = ['#35aca2', '#58bbe5', '#ea4f6e', '#f2b020', '#8d4bd9', '#b03386', '#d8d34e'];
export const contrastColors = ['#e0dd0b', '#c1ffab', '#c39af5', '#bd1919', '#adc7ff', '#4643b0', '#9c794c'];
export const regions = ['afr', 'amr', 'csa', 'eas', 'eur', 'mid', 'oce'];

export const getRegionIndex = (region: string) => {
  return ANCESTRY_REGIONS_LIST.indexOf(region.toString().toLowerCase());
};

export const getColors = (
  groupIndex: number,
  groupLength: number = 5,
  highlightedGroup: number | null = null,
  geneticRegion: string,
) =>
  highlightedGroup === groupIndex
    ? '#fffff'
    : scaleLinear<string>()
        .domain([0, groupLength + 2])
        .range([regionColors[getRegionIndex(geneticRegion)], contrastColors[getRegionIndex(geneticRegion)]])(
        groupIndex! + 1,
      );

export const useStyles = () => {
  return {
    ancestry: css`
      path,
      line {
        stroke: ${theme.palette.primary.lighter};
        fill: ${theme.palette.primary.lighter};
        stroke-width: 0.5px;
      }
      text {
        font-size: 0.5rem;
      }
      .percentage {
        stroke-width: 0.5px;
      }
      .hidden {
        fill: none !important;
        stroke: none !important;
      }
      .label {
        fill: white;
        font-weight: 600;
      }
    `,
    ancestryListItemText: css`
      path,
      line {
        stroke: ${theme.palette.primary.light};
        fill: ${theme.palette.primary.light};
        stroke-width: 0.5px;
      }
    `,
    ancestryInfoBox: css`
      margin-top: 20px;
      padding: 32px;
      background: ${theme.palette.info.lighter};
      li {
        margin-bottom: 15px;
      }
    `,
    ancestryDisplayStatusBox: css`
      padding: 2px 20px 2px 20px;
      background: ${theme.palette.info.lighter};
    `,
    ancestryHowCanYouHelpBox: css`
      margin-top: 20px;
      padding: 32px;
      li {
        margin-bottom: 15px;
      }
    `,
    ancestryFormErrorMsg: css`
      color: ${theme.palette.error.main};
    `,
    ancestryFormSuccessBox: css`
      margin-top: 20px;
      padding: 32px;
      background: ${theme.palette.success.lighter};
    `,
  };
};
