/** @jsxImportSource @emotion/react */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { theme } from '@omnigenbiodata/ui';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface AncestryTableData {
  groups: AncestryTableDataItem[];
  geneticRegionLabel: string;
  geneticRegion: string;
}

interface AncestryTableDataItem {
  group: string;
  groupLabel: string;
  geneticRegion: string;
  groupDescription: string;
}

export interface AncestryTableInfoProps {
  expandedPanel: number | null;
  handleAccordionChange: any;
  ancestryTableData: AncestryTableData[];
  selectedRow: any;
  handleGroupRowClick: any;
  testId: string;
}

function AncestryTableInfo({
  expandedPanel,
  handleAccordionChange,
  ancestryTableData,
  selectedRow,
  handleGroupRowClick,
  testId,
}: AncestryTableInfoProps) {
  const { t } = useTranslation('portal');
  const getRegionTranslationKey = (geneticRegion: string): string => {
    const regionKey = `ancestry.regionNames.${geneticRegion}`;
    return regionKey;
  };

  return (
    <div>
      {ancestryTableData.map((region, index) => (
        <>
          <Accordion
            key={`accordion_region_${region.geneticRegion}`}
            data-testid={`${testId}_accordion_region_${region.geneticRegion}`}
            expanded={expandedPanel === index} // open the panel based on state
            onChange={() => handleAccordionChange(index)}
          >
            <AccordionSummary
              key={`accordion_summary_region_${region.geneticRegion}`}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <strong>{t(getRegionTranslationKey(region.geneticRegion))}</strong>
            </AccordionSummary>
            <AccordionDetails key={`accordion_details_region_${region.geneticRegion}`}>
              <TableContainer component={Paper} elevation={0} key={`table_container_region_${region.geneticRegion}`}>
                <Table
                  key={`region_${region}_desc_table`}
                  sx={{ minWidth: 650, borderRadius: 10 }}
                  aria-label="Ancestry data table"
                  data-testid={`${testId}_region_${region.geneticRegion}_desc_table`}
                >
                  <TableBody>
                    {region.groups.map((group) => (
                      <TableRow
                        data-testid={`${testId}_region_group_${group.group}_row`}
                        key={`region_group_${group.group}_row`}
                        sx={{
                          background: theme.palette.grey[50],
                          cursor: 'pointer',
                          ...(selectedRow === group.group ? { backgroundColor: theme.palette.success.lighter } : null),
                        }}
                        onClick={() => handleGroupRowClick(group.geneticRegion, group.group)}
                      >
                        <TableCell
                          key={`region_group_${group.group}_label_col`}
                          component="th"
                          scope="row"
                          data-testid={`${testId}_region_group_${group.group}_label_col`}
                          sx={{ border: `1px solid ${theme.palette.grey[200]}` }}
                        >
                          <strong>{group.groupLabel}</strong>
                        </TableCell>
                        <TableCell
                          key={`region_group_${group.group}_desc_col`}
                          sx={{ border: `1px solid ${theme.palette.grey[200]}` }}
                        >
                          {group.groupDescription}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        </>
      ))}
    </div>
  );
}

export default AncestryTableInfo;
